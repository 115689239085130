import React, { useEffect, useState, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useGesture } from "react-use-gesture";
import cx from "classnames";
import { Link } from "gatsby";
import {
  motion,
  useMotionValue,
  useSpring,
  AnimatePresence,
} from "framer-motion";

import { ModuleWrapper } from "../moduleWrapper";
// import SwitchLink from '@components/links/switchLink';
import LotiePlayer from "@components/media/lottiePlayer";
import ChevronPrev from "@svg/chevron-prev.svg";
import ChevronNext from "@svg/chevron-next.svg";

const cursorVars = {
  visible: {
    scale: 1,
    opacity: 1,
  },
  dragging: {
    scale: 0.5,
    opacity: 1,
  },
  hidden: {
    scale: 0.2,
    opacity: 0,
  },
};

const cursorTextVars = {
  visible: {
    opacity: 1,
  },
  dragging: {
    opacity: 0,
  },
  hidden: {
    opacity: 0,
  },
};

const PrevButton = ({ enabled, onClick }) => (
  <button
    className={cx(
      "md:hidden transition-all ease-in-out duration-300 mr-4 w-12 h-12 rounded-full cursor-pointer touch-manipulation bg-red bg-opacity-10  ",
      {
        "opacity-20": !enabled,
      }
    )}
    onClick={onClick}
    disabled={!enabled}
  >
    <ChevronPrev className="m-auto" />
  </button>
);

const NextButton = ({ enabled, onClick }) => (
  <button
    className={cx(
      "md:hidden transition-all ease-in-out duration-300 ml-4  w-12 h-12 rounded-full cursor-pointer touch-manipulation bg-red bg-opacity-10 ",
      {
        "opacity-20": !enabled,
      }
    )}
    onClick={onClick}
    disabled={!enabled}
  >
    <ChevronNext className="m-auto" />
  </button>
);

const CardWrapper = ({ wrapper, children }) => {
  return wrapper(children);
};

const Card = ({ title, excerpt, icon, lottieFile, isLottie, cardLink }) => {
  const [isPlaying, setPlaying] = React.useState(false);
  const breakpoints = useBreakpoint();
  console.log(cardLink);
  return (
    <div
      className="w-full bg-white p-6 flex flex-shrink-0 rounded-xl md:w-1/2  lg:max-w-md lg:transform lg:scale-95 lg:hover:scale-100 lg:transition-all lg:duration-500 lg:p-10"
      onMouseOver={() => setPlaying(true)}
      onMouseOut={() => setPlaying(false)}
    >
      <CardWrapper
        wrapper={(children) =>
          cardLink[0]?._type === "pageLink" ? (
            // <SwitchLink
            //   {...cardLink[0]}
            //   className="grid gap-y-0 self-stretch md:gap-y-0 cursor-none"
            // >
            //   {children}
            // </SwitchLink>
            <Link
              className="grid gap-y-20 self-stretch md:gap-y-0 cursor-none"
              to={`/${cardLink[0]?.page?.slug?.current}`}
            >
              {children}
            </Link>
          ) : cardLink[0]?._type === "externalLink" ? (
            <a
              href={cardLink[0]?.link}
              target="_blank"
              rel="noreferrer"
              className="grid gap-y-20 self-stretch md:gap-y-0 cursor-none"
            >
              {children}
            </a>
          ) : (
            <div className="grid gap-y-20 self-stretch md:gap-y-0 cursor-none">
              {children}
            </div>
          )
        }
      >
        <div>
          <h4 className="text-xl text-red mb-one lg:text-4xl">{title}</h4>
          <h5 className="text-lg leading-relaxed lg:text-2xl">{excerpt}</h5>
        </div>
        <div className="self-end">
          {!isLottie && (
            <div
              dangerouslySetInnerHTML={{ __html: icon }}
              className="w-64 h-64 ml-auto lg:w-80 lg:h-80 svg-wrapper"
            />
          )}
          {isLottie && lottieFile?.asset && (
            <LotiePlayer
              url={lottieFile.asset.url}
              className="ml-auto mt-8"
              playing={breakpoints.lg ? isPlaying : true}
              autoplay={false}
            />
          )}
        </div>
      </CardWrapper>
    </div>
  );
};

const IconCardCarousel = ({ config, cards }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    loop: false,
    align: "start",
    containScroll: true,
    dragFree: true,
  });

  const [cusorActive, setCursorActive] = useState(false);
  const [isDrag, setDrag] = useState(false);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();

    embla.on("select", onSelect);
  }, [embla, onSelect]);

  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 40, stiffness: 300 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  const bind = useGesture({
    onHover: ({ hovering }) => setCursorActive(hovering),
    onDrag: ({ dragging }) => setDrag(dragging),
    onMove: ({ values }) => {
      cursorX.set(values[0] - 40);
      cursorY.set(values[1] - 40);
    },
  });

  return (
    <>
      <AnimatePresence>
        {cusorActive && (
          <motion.div
            style={{
              width: "80px",
              height: "80px",
              translateX: cursorXSpring,
              translateY: cursorYSpring,
            }}
            className="fixed left-0 top-0 rounded-full z-50 pointer-events-none cursor-none flex items-center cards-center justify-center drag-cursor bg-red md:bg-red-light text-red"
            variants={cursorVars}
            initial="hidden"
            animate={isDrag ? "dragging" : "visible"}
            exit="hidden"
          >
            <motion.span className="font-medium" variants={cursorTextVars}>
              &lt; Drag &gt;
            </motion.span>
          </motion.div>
        )}
      </AnimatePresence>

      {/* <DragCursor> */}
      <ModuleWrapper {...config} className="overflow-hidden" revealAnimation>
        <div ref={viewportRef} className="w-full px-gutter" {...bind()}>
          <div className="flex gap-x-4 lg:gap-x-6">
            {cards.map((item) => (
              <Card {...item} />
            ))}
          </div>
        </div>
        <div className="text-center mt-10">
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
      </ModuleWrapper>
      {/* </DragCursor> */}
    </>
  );
};

export default IconCardCarousel;
